import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getProductStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stock/getProductStock', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stock/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchGroupByWarehouseIdAndCompanyIdAndStatusAndProductId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/searchGroupByWarehouseIdAndCompanyIdAndStatusAndProductId', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    operateSearchStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/operateSearchStock', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stock/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/stock/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    checkmax(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/getStock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    addapply(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/addapply', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/getStock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeProductQty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/changeProductQty', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    stockModify(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/stockModify', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    stockModifyYF(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/stockModifyYF', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    stockTransfer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/stockTransfer', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getChangeCost(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/getChangeCost', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchAllInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/stock/searchAllInfo', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    sendEveryDayStockToEmail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/sendEveryDayStockToEmail', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchStockOutboundOutBound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/searchStockOutboundOutBound', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchStockOutboundOutBoundItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/searchStockOutboundOutBoundItem', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchStockOutboundInBound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/stock/searchStockOutboundInBound', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStockCost(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/changeStockCost', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getStockLock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/getStockLock', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchProductAllInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/searchProductAllInfo', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportToUpdateExpiryDate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/stock/exportToUpdateExpiryDate', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

  },
}
