import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stockUseList(config) {
  // Use toast
  const toast = useToast()

  const stockTable = ref(null)
  const batchTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'ware_house_name', label: '仓库名称' },
    { key: 'detail_good', label: '良品' },
    { key: 'detail_bad', label: '不良品' },
    { key: 'first_expire_str', label: '最早过期' },
    { key: 'last_expire_str', label: '最晚过期' },
  ]
  const tableColumnBatch = [
    { key: 'ware_house', label: '仓库名称' },
    { key: 'batch_no', label: '批次' },
    { key: 'status_str', label: '状态' },
    { key: 'production_date_str', label: '最早过期' },
    { key: 'expiry_date_str', label: '最晚过期' },
    { key: 'stock_qty', label: '库存' },
    // { key: 'stock_qty_lock', label: '锁库' },
    { key: 'over_expiry', label: '过保' },
  ]

  if(config.isShow.value){
    // tableColumnBatch.push({ key: 'stocklock', label: '锁库' })
  }

  const listTotals = ref(0)


  const refetchData = () => {
    stockTable.value.refresh()
    batchTable.value.refresh()
  }

  const formatData = (data) => {
    if(data !== undefined && data['product_stock_warehouse'] !== undefined){
    let productStockWarehouse = data['product_stock_warehouse']

      productStockWarehouse.forEach(item =>{
        item['product_stock_detail'].forEach((temp,index)=>{
          if (temp['stock_name'] === "不良品"){
            item[`detail_bad`] = temp["stock_qty"]
          }else{
            item[`detail_good`] = temp["stock_qty"]
          }
        })
      })
      data['product_stock_warehouse'] = productStockWarehouse
    }
    return data
  }

  const wareHouseList = ref([])
  const batchList = ref([])
  const totalStock = ref(0)
  const totalStockLock = ref(0)


  let stockMap = async () => {
    let tempMap = await  store
      .dispatch('stock/getProductStock', {
        productid:config.productId.value
      })
    let temp = formatData(tempMap.data.data)
    wareHouseList.value = temp['product_stock_warehouse']
    batchList.value = temp['product_stock_batch']
    totalStock.value = temp['total_stock']
    totalStockLock.value = temp['total_stock_lock']
  }
  /*let stockMap =  () => {
    let tempMap =  getProductStock()
    console.log(tempMap)
    wareHouseList.value = tempMap['product_stock_warehouse']
    batchList.value = tempMap['product_stock_batch']
  }*/
  onMounted(stockMap)
  const getProductStock = (ctx, callback) => {
    store
      .dispatch('stock/getProductStock', {
        productid:config.productId.value
      })
      .then(response => {
        const data = response.data.data
        let temp = formatData(data)
      })
     /* .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })*/

  }



  return {
    tableColumns,
    stockMap,
    batchList,
    wareHouseList,
    totalStockLock,
    totalStock,
    stockTable,
    batchTable,
    refetchData,

    tableColumnBatch
    // Extra Filters
  }
}
