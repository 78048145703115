<template>

  <div>
    <b-tabs>
      <b-tab title="仓库维度" active><b-card
          no-body
          class="mb-0"
      >

        <!--      <div class="m-2">

                &lt;!&ndash; Table Top &ndash;&gt;
                <b-row>

                  &lt;!&ndash; Per Page &ndash;&gt;
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>显示</label>
                    <v-select
                      v-model="limit"
                      :options="limitOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>条</label>
                  </b-col>

                  &lt;!&ndash; Search &ndash;&gt;
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchKey"
                        class="d-inline-block mr-1"
                        placeholder="搜索关键字..."
                      />
                      <b-button
                        variant="primary"
                        :to="{ name: 'apps-stock-edit'}"
                      >
                        <span class="text-nowrap">添加</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>

              </div>-->

        <b-table
            ref="stockTable"
            class="position-relative"
            :items="wareHouseList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="未找到记录"
        >

          <!--        &lt;!&ndash; Columns &ndash;&gt;
                  <template #cell(id)="data">
                    <b-link
                        :to="{ name: 'apps-stock-edit', query: { id: data.item.id } }"
                        class="font-weight-bold d-block text-nowrap"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </template>

                  &lt;!&ndash; Column: Type &ndash;&gt;
                  <template #cell(stock_type)="data">
                    {{resolveStockTypeCode(data.item.stock_type).label}}
                  </template>

                  &lt;!&ndash; Column: State &ndash;&gt;
                  <template #cell(state)="data">
                    <b-link
                      @click="changeState(data.item)"
                    >
                    <b-badge
                      pill
                      :variant="`light-${resolveStateCode(data.item.state).color}`"

                    >
                      {{resolveStateCode(data.item.state).label}}
                    </b-badge>
                    </b-link>
                  </template>

                  &lt;!&ndash; Column: Actions &ndash;&gt;
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item :to="{ name: 'apps-stock-view', query: { id: data.item.id } }">
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">浏览</span>
                      </b-dropdown-item>

                      <b-dropdown-item :to="{ name: 'apps-stock-edit', query: { id: data.item.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">编辑</span>
                      </b-dropdown-item>

                    </b-dropdown>
                  </template>-->

        </b-table>
        <!--      <div class="mx-2 mb-2">
                <b-row>

                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
                  </b-col>
                  &lt;!&ndash; Pagination &ndash;&gt;
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="start"
                      :total-rows="listTotals"
                      :per-page="limit"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>

                </b-row>
              </div>-->
      </b-card></b-tab>
      <b-tab title="批次维度">
        <b-table
          ref="batchTable"
          class="position-relative"
          :items="batchList"
          responsive
          :fields="tableColumnBatch"
          primary-key="id"
          show-empty
          empty-text="未找到记录"
        >

          <template #cell(stocklock)="data">
            <div v-if="data.item.status == 0">
              <b-button variant="success" @click="showLockModal(data.item.stock_id)">申请锁库</b-button>
            </div>
            <div v-if="data.item.status != 0">
              <b-button>申请锁库</b-button>
            </div>
          </template>

        </b-table>
      </b-tab>


      <b-modal
          id="stocklockModal"
          ok-only
          ok-title="确认"
          cancel-title="取消"
          hide-footer
          centered
          size="lg"
          :title="'库存锁库申请'"
      >
        <validation-observer ref="simpleRules">
          <b-form>
          <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label="锁库数量"
              label-for="input-default"
          >
            <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Number"
            >
              <b-form-input
                  v-model="qtylock"
                  :state="errors.length > 0 ? false:null"
                  @change="checkmax()"
              />
              <small class="text-danger" v-if="errors[0]">请输入数字</small>
            </validation-provider>


          </b-form-group>

          <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label="开始时间"
              label-for="input-default"
          >
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="starttime"
            >
              <flat-pickr
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  v-model="starttime"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger" v-if="errors[0]">请输入开始时间</small>
            </validation-provider>



          </b-form-group>

          <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label="结束时间"
              label-for="input-default"
          >
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="endtime"
            >
              <flat-pickr
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  v-model="endtime"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger" v-if="errors[0]">请输入结束时间</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
              label-cols="4"
              label-cols-lg="2"
              label="申请原因"
              label-for="input-default"
          >
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="reason"
            >
              <b-form-input
                  id="reason"
                  size="sm"
                  v-model="reason"
              />
              <small class="text-danger" v-if="errors[0]">请输入申请原因</small>
            </validation-provider>
          </b-form-group>

            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              提 交
            </b-button>
          </b-form>
        </validation-observer>

      </b-modal>

    </b-tabs>
    <!-- Table Container Card -->

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTabs,BTab,BFormGroup,BForm
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, toRefs } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import stockUseList from './stockUseList'
import stockStore from '../stockStore'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTabs,
    BTab,
    flatPickr,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  data(){
    return {
      stockid:0,
      qtylock:0,
      starttime:"",
      endtime:"",
      reason:"",
    }
  },
  props:{
    productId :{
      type : Number,
      default : 0
    },
    isShow:{
      type:Boolean,
      default: true
    }
  },
  methods: {
    getStockStr:function (){
      return this.totalStock === undefined ? "暂无数据" : `总：${this.totalStock+this.totalStockLock}(可用：${this.totalStock} + 锁库：${this.totalStockLock})`
    }
  },
  watch:{
    totalStock:(val,newVal) => {
      /*console.log(val)
      console.log(newVal)*/
    }
  },
  setup(props) {
    const toast = useToast()
    const { productId,isShow } = toRefs(props)
    // Register module
    if (!store.hasModule('stock')) store.registerModule('stock', stockStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stock')) store.unregisterModule('stock')
    })

    const {
      stockMap,
      tableColumns,
      stockTable,
      batchTable,
      totalStockLock,
      totalStock,
      refetchData,
      wareHouseList,
      tableColumnBatch,
        batchList
    } = stockUseList({
      productId : productId,
      isShow: isShow
    })

    const showLockModal = function (stockid){
      this.$bvModal.show('stocklockModal')
      this.stockid = stockid
    }

    const checkmax = function (){
      store
          .dispatch('stock/checkmax', {stockid: this.stockid,stocklockid:0})
          .then(res => {
            if(res.data.code == 0) {
              if (parseInt(this.qtylock) > parseInt(res.data.data.qty)) {
                this.qtylock = res.data.data.qty
              }
            }else{
              this.qtylock = 0
            }
          })
          .catch((e) => {
            this.qtylock = 0
          })
    }

    const validationForm = function () {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(parseInt(this.qtylock) <= 0){
            toast.error("锁库数量必须大于零")
            return false
          }
          if(this.starttime == ""){
                toast.error("请选择开始时间")
            return false
          }
          if(this.endtime == ""){
            toast.error("请选择结束时间")
            return false
          }
          if(this.reason == ""){
            toast.error("请选择结束时间")
            return false
          }

          store
              .dispatch('stock/addapply', {stockid: this.stockid,qtylock:this.qtylock,starttime:this.starttime,endtime:this.endtime,reason:this.reason})
              .then(res => {
                if(res.data.code == 0) {
                  this.$bvModal.hide('stocklockModal')
                  toast.success("申请成功，等待申请通过")
                }else{
                  toast.error(res.data.data)
                }
              })

        }
      })
    }

    return {
      wareHouseList,
      batchList,
      stockMap,
      tableColumns,
      stockTable,
      batchTable,
      totalStockLock,
      totalStock,
      refetchData,
      tableColumnBatch,
      // Filter
      avatarText,
      showLockModal,
      checkmax,
      validationForm,
    }
  },
  created() {
    // this.stockMap()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
