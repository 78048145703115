import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/product/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductById(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/findProductByProductId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getProductId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/getProductByCode', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/product/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/product/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/product/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeEggErpCode(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/changeEggErpCode', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    select(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/product/select', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    selectProduce(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/product/selectProduce', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    selectBom(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/product/selectBom', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/product/changeStatus', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    reduce(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/product/reduce', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    export(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/product/export', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    copyProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/product/copyProduct', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeBox(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/changeBox', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveProductBox(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/saveProductBox', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    uploadPicture(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/uploadPicture', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    uploadDetails(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/uploadDetails', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    pushBBMall(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/bbmall/product/v1/push', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    queryBarcode(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/queryBarcode', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    categoryFix(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/product/categoryFix', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
